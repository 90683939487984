var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"collaborationbrowsing study-active-introduce"},[_c('ActivityLinkInfo',{attrs:{"activityInfoData":_vm.activityInfoData,"infoData":_vm.resourceData}}),_c('div',{staticClass:"main_content"},[_c('div',{staticClass:"title-box"},[_vm._v("会议")]),(_vm.resourceData.implement_type == '1')?_c('div',[_c('div',{staticClass:"nine"},[_c('div',{staticClass:"images"},[_c('img',{attrs:{"src":_vm.resourceData.live_data.cover_url}})]),_c('div',{staticClass:"nine_word"},[_c('p',[_vm._v(" "+_vm._s(_vm.resourceData.live_data.title)+" ")]),_c('p',[_c('span',{staticStyle:{"color":"#999"}},[_vm._v("主播：")]),_c('span',[_vm._v(_vm._s(_vm.resourceData.live_data.anchor_user))])]),_c('p',[_c('span',{staticStyle:{"color":"#999"}},[_vm._v("开播时间：")]),_vm._v(" "+_vm._s(_vm.resourceData.live_data.time)+" ")])]),(_vm.$store.state.activity_isEnable)?[(
                _vm.isLive &&
                (_vm.resourceData.live_data.status == 1 || _vm.resourceData.live_data.status == 2)
              )?_c('div',{staticClass:"enter_live",on:{"click":function($event){return _vm.startLive()}}},[_vm._v(" 开始会议 ")]):_vm._e(),(
                !_vm.isLive &&
                _vm.resourceData.live_data.status == 2 &&
                _vm.$store.state.activity_isJoin
              )?_c('div',{staticClass:"enter_live",on:{"click":function($event){return _vm.enterLive()}}},[_vm._v(" 进入会议 ")]):_vm._e(),(
                _vm.dcConfig.key &&
                _vm.isLive &&
                _vm.resourceData.live_data.status == 3 &&
                _vm.resourceData.live_data.live_upload == 2
              )?_c('dc-upload',{ref:"upload",staticClass:"upload-demo",attrs:{"dcAutoUpload":true,"limit":1,"dcConfig":_vm.dcConfig,"accept":".mp4,.MP4"},on:{"dc-success":_vm.dcFile}},[_c('div',{staticClass:"enter_live",attrs:{"slot":"trigger"},slot:"trigger"},[_vm._v("上传回放")]),_c('div',{staticStyle:{"font-size":"14px","color":"#fff","margin-top":"8px"},attrs:{"slot":"tip"},slot:"tip"},[_vm._v(" 请上传mp4格式视频，限制1个 ")])]):(
                _vm.resourceData.live_data.status == 4 &&
                _vm.$store.state.activity_isJoin &&
                _vm.resourceData.live_data.review_url
              )?_c('div',{staticClass:"enter_live",on:{"click":function($event){return _vm.enterLive()}}},[_vm._v(" 会议回放 ")]):(
                _vm.resourceData.live_data.status == 3 && _vm.$store.state.activity_isJoin
              )?_c('div',{staticClass:"end_live"},[_vm._v(" 会议已结束 ")]):_vm._e()]:_vm._e()],2)]):(_vm.resourceData.implement_type == '2')?_c('UnderLineDetail',{attrs:{"is_from":+_vm.is_from,"resourceData":_vm.resourceData,"activityId":+_vm.activityId,"linkId":+_vm.id},on:{"getResourceData":_vm.getResourceData}}):_vm._e()],1),(_vm.isLive)?_c('div',{staticClass:"live-plug-in"},[_c('span',{staticClass:"plug-in-button",on:{"click":function($event){return _vm.downloadLiveSoftware()}}},[_vm._v("下载直播软件")])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }